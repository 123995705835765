export const EMAIL_VALIDATION_REGEX: RegExp = new RegExp(
    `^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))`,
);
export const EMPTY_REGEX: RegExp = /^\s*$/;
export const COMMA_REGEX: RegExp = /,/g;
export const OTHER_REGEX: RegExp = /^other$/i;
export const SPECIAL_CHARACTERS_REGEX: RegExp = new RegExp('[-[\\]{}()*+?.,\\\\^$|#\\s]', 'g');
export const REFERENCE_REGEX: RegExp = /(\[[0-9]+])/g;
export const REF_TAG_REGEX: RegExp = /<ref>(.*?)<\/ref>/g;
export const TSV_FILE_TYPE_REGEX: RegExp = /\.tsv$/i;
export const US_LOCALE_NUMBER_REGEX: RegExp =
    /^(?!.*,,)(?:(?:\d{1,3}(?:,\d{3})*)|\d+)(?:\.\d{1,2})?$/;
